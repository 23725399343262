import { createStore } from 'vuex'
import createPersistedstate from 'vuex-persistedstate'
export default createStore({
  state: {
    isCollapse: true,
    currentMenu: null,
    tabsList: [
      {
        path: '/',
        name: 'home',
        label: '首页',
        icon: 'home',
      }
    ]
  },
  getters: {

  },
  mutations: {
    updateIsCollapse(state, payload) {
      state.isCollapse = !state.isCollapse
    },
    selectMenu(state, val) {
      // panduan
      // val.name =='home'? (state.currentMenu =null):(state.currentMenu =val)

      if (val.name == 'home') {
        state.currentMenu = null
      } else {
        state.currentMenu = val
        let result = state.tabsList.findIndex(item => item.name == val.name)
        result == -1 ? state.tabsList.push(val) : ''
      }
    },
    closeTab(state, val) {
      let res = state.tabsList.findIndex(item => item.name == val.name)
      state.tabsList.splice(res, 1)
    }
  },
  actions: {
  },
  modules: {
  },
  plugins:[
    createPersistedstate({
      key:'usermessage'
    })
  ]
})
