<template>
	<el-aside :width="$store.state.isCollapse ? '250px' : '64px'">
		<el-menu
			class="nemuLose"
			background-color="#1D3895"
			text-color="#fff"
			active-text-color="#fff"
			:collapse="!$store.state.isCollapse"
			:collapse-transition="false"
			:default-active="deItem"
		>
			<h3 v-show="$store.state.isCollapse">后台管理</h3>
			<h3 v-show="!$store.state.isCollapse">后台</h3>
			<el-menu-item
				:index="item.path"
				v-for="item in noChilden()"
				:key="item.path"
				@click="clickMenu(item)"
			>
				<el-icon>
					<img src="../assets/images/home.svg" alt="" class="icons" />
				</el-icon>
				<span>{{ item.label }}</span>
			</el-menu-item>
			<el-sub-menu
				:index="item.path"
				v-for="item in hasChilden()"
				:key="item.path"
			>
				<template #title>
					<el-icon>
						<component class="icons" :is="item.icon"></component>
					</el-icon>
					<span>{{ item.label }}</span>
				</template>
				<el-menu-item
					:index="subItem.path"
					v-for="(subItem, subIndex) in item.children"
					:key="subIndex"
					@click="clickMenu(subItem)"
				>
					<component class="icons" :is="subItem.icon"></component>
					<span>{{ subItem.label }}</span>
				</el-menu-item>
			</el-sub-menu>
		</el-menu>
	</el-aside>
</template>
<script>
import router from "@/router";
import { ref, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
export default {
	setup() {
		const store = useStore();
		const route = useRoute();
		const list = [
			{
				label: "其他",
				icon: "location",
				path: "other",
				children: [
					{
						path: "/page1",
						name: "page1",
						label: "页面1",
						icon: "setting",
						url: "Other/PageOne",
					},
					{
						path: "/page2",
						name: "page2",
						label: "页面2",
						icon: "setting",
						url: "Other/PageTwo",
					},
				],
			},
			{
				path: "/",
				name: "home",
				label: "首页",
				icon: "HomeFilled",
				url: "/",
			},
			{
				path: "/zhanghu",
				name: "zhanghu",
				label: "用户账户管理",
			},
			{
				path: "/renwu",
				name: "renwu",
				label: "预算任务列表",
			},
			{
				path: "/lixiang",
				name: "lixiang",
				label: "预算立项",
			},
			{
				path: "/bumenxinxi",
				name: "bumenxinxi",
				label: "部门信息管理",
			},
			{
				path: "/tiaomu",
				name: "tiaomu",
				label: "预算条目配置",
			},
			{
				path: "/liucheng",
				name: "liucheng",
				label: "流程配置",
			},
			{
				path: "/shenqing",
				name: "shenqing",
				label: "提报销申请",
			},
			{
				path: "/caogao",
				name: "caogao",
				label: "草稿",
			},
			{
				path: "/daishenpi",
				name: "daishenpi",
				label: "待审批清单",
			},
			{
				path: "/shenpizhong",
				name: "shenpizhong",
				label: "审批中清单",
			},
			{
				path: "/yishenpi",
				name: "yishenpi",
				label: "已审批清单",
			},
			{
				path: "/yibaoxiao",
				name: "yibaoxiao",
				label: "已报销清单",
			},
			{
				path: "/peizhi",
				name: "peizhi",
				label: "报销流程配置",
			},
			{
				path: "/cjyjgz",
				name: "cjyjgz",
				label: "创建预警规则",
			},
			{
				path: "/cjgzlb",
				name: "cjgzlb",
				label: "预警规则列表",
			},
			{
				path: "/cf",
				name: "cf",
				label: "预算编制/拆分",
			},
			{
				path: "/dbz",
				name: "dbz",
				label: "预算编制/待编制",
			},
			{
				path: "/dsh",
				name: "dsh",
				label: "预算编制/待审核",
			},
			{
				path: "/sh",
				name: "sh",
				label: "预算编制/审核",
			},
			{
				path: "/tj",
				name: "tj",
				label: "预算编制/提交",
			},
			{
				path: "/wdrw",
				name: "wdrw",
				label: "预算编制/我的任务",
			},
			{
				path: "/ysh",
				name: "ysh",
				label: "预算调剂/审核",
			},
			{
				path: "/ysq",
				name: "ysq",
				label: "预算调剂/申请",
			},
			{
				path: "/zsq",
				name: "zsq",
				label: "预算调整/申请",
			},
			{
				path: "/zsh",
				name: "zsh",
				label: "预算调整/审核",
			},
			{
				path: "/kmlbpz",
				name: "kmlbpz",
				label: "科目类别配置",
			},
			{
				path: "/kmxzpz",
				name: "kmxzpz",
				label: "科目性质配置",
			},
			{
				path: "/wllxpz",
				name: "wllxpz",
				label: "往来类型配置",
			},
			{
				path: "/bdlxpz",
				name: "bdlxpz",
				label: "表单类型配置",
			},
			{
				path: "/ndpz",
				name: "ndpz",
				label: "年度配置",
			},
			{
				path: "/ybpz",
				name: "ybpz",
				label: "样表配置",
			},
			{
				path: "/yslxpz",
				name: "yslxpz",
				label: "预算类型配置",
			},
			{
				path: "/kjkmpz",
				name: "kjkmpz",
				label: "会计科目配置",
			},
			{
				path: "/lcpz",
				name: "lcpz",
				label: "流程配置",
			},
			{
				path: "/qxpz",
				name: "qxpz",
				label: "权限配置",
			},
		];
		const deItem = ref(route.path);
		watchEffect(() => {
			deItem.value = route.path;
		});
		const noChilden = () => {
			return list.filter((item) => !item.children);
		};
		const hasChilden = () => {
			return list.filter((item) => item.children);
		};
		const clickMenu = (item) => {
			router.push({
				name: item.name,
			});
			// 通过vuex来实现面包屑的通信
			store.commit("selectMenu", item);
		};
		return {
			noChilden,
			hasChilden,
			clickMenu,
			deItem,
		};
	},
};
</script>
<style lang="less" scoped>
.icons {
	width: 18px;
	height: 18px;
}
.nemuLose {
	min-height: 100vh;
}
h3 {
	line-height: 48px;
	color: #fff;
	text-align: center;
}
.el-menu--vertical .el-menu-item.is-active {
	background-color: #3680fd; /* 你想要的背景色 */
}
</style>
