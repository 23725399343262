import Mock from 'mockjs'
import homeApi from './mockData/home'
import countData from './mockData/countData'
import echartsData from './mockData/echartsData'
import userApi from './mockData/userData'
// 拦截请求
Mock.mock('/home/getData', homeApi.getHomeData)
Mock.mock('/api/getData',countData.getCountData)
Mock.mock('/api/echartsData',echartsData.getEchartsData)

Mock.mock('/user/getUser','get',userApi.getUserList)