export default {
    getEchartsData: () => {
        return {
            code: 200,
            data: {
                'orderData': {
                    "date": ['20191001', '20191002', '20191003', '20191004', '20191005', '20191006', '20191007'],
                    "data": [{
                        "苹果": 1234,
                        "小米": 5678,
                        "华为": 3456,
                        "oppo": 2345,
                        "vivo": 6789,
                        "一加": 5432
                    },
                    {
                        "苹果": 5432,
                        "小米": 3456,
                        "华为": 4567,
                        "oppo": 1234,
                        "vivo": 2345,
                        "一加": 6789
                    },
                    {
                        "苹果": 3245,
                        "小米": 2356,
                        "华为": 1234,
                        "oppo": 4567,
                        "vivo": 5678,
                        "一加": 6789
                    },
                    {
                        "苹果": 1234,
                        "小米": 5678,
                        "华为": 3456,
                        "oppo": 2345,
                        "vivo": 6789,
                        "一加": 5432
                    },
                    {
                        "苹果": 5432,
                        "小米": 3456,
                        "华为": 4567,
                        "oppo": 1234,
                        "vivo": 2345,
                        "一加": 6789
                    },
                    {
                        "苹果": 3245,
                        "小米": 2356,
                        "华为": 1234,
                        "oppo": 4567,
                        "vivo": 5678,
                        "一加": 6789
                    },
                    {
                        "苹果": 1234,
                        "小米": 5678,
                        "华为": 3456,
                        "oppo": 2345,
                        "vivo": 6789,
                        "一加": 5432
                    }]
                },
                'videoData': [{
                    name: '小米',
                    value: 2999
                },
                {
                    name: '苹果',
                    value: 5999
                },
                {
                    name: 'vivo',
                    value: 1500
                },
                {
                    name: 'oppo',
                    value: 1999
                },
                {
                    name: '魅族',
                    value: 2200
                },
                {
                    name: '三星',
                    value: 4500
                }
                ],
                'userData': [{
                    date: '周一',
                    new: 5,
                    active: 200
                },
                {
                    date: '周二',
                    new: 10,
                    active: 500
                },
                {
                    date: '周三',
                    new: 12,
                    active: 550
                },
                {
                    date: '周四',
                    new: 60,
                    active: 800
                },
                {
                    date: '周五',
                    new: 65,
                    active: 550
                },]
            }
        }
    }
}