<template>
    <div class="common-layout">
      <el-container>
        <CommonAside class="aside"></CommonAside>
        <el-container>
          <el-header style="padding: 0;">
            <CommonHeader></CommonHeader>
            <CommonTab></CommonTab>
          </el-header>
          <el-main style="margin-top: 20px;">
            <router-view></router-view>
          </el-main>
        </el-container>
      </el-container>
    </div>
  </template>
<script>
import { defineComponent } from 'vue';
import CommonHeader from '@/components/CommonHeader.vue';
import CommonAside from '@/components/CommonAside.vue';
import CommonTab from '@/components/CommonTab.vue';
export default defineComponent({
    components:{
        CommonHeader,
        CommonAside,
        CommonTab
    }
})
</script>
<style scoped>
.aside {
    height: 100vh;
}
.aside::-webkit-scrollbar {
    width: 0;
}
</style>