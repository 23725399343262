<template>
	<div class="wrap">
		<div class="tags" ref="tagscroll">
			<el-tag
				v-for="(tag, index) in tags"
				:key="tag.name"
				:closable="tag.name !== 'home'"
				:effect="$route.name === tag.name ? 'light' : 'plain'"
				@click="changMenu(tag)"
				@close="handleClose(tag, index)"
				color="#fff"
				class="tag"
			>
				<span class="isShow" v-if="$route.name === tag.name"></span
				>{{ tag.label }}
			</el-tag>
		</div>
		<div class="prev" @click="clickPrev">
			<el-icon><Back /></el-icon>
		</div>
		<div class="next" @click="clickNext">
			<el-icon><Right /></el-icon>
		</div>
	</div>
</template>
<script>
import router from "@/router";
import { getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
export default {
	setup() {
		const store = useStore();
		const route = useRoute();
		const { proxy } = getCurrentInstance();
		const tags = store.state.tabsList;
		const clickPrev = () => {
			proxy.$refs.tagscroll.scrollLeft -= 111;
		};
		const clickNext = () => {
			proxy.$refs.tagscroll.scrollLeft += 111;
		};
		const changMenu = (item) => {
			router.push({ name: item.name });
		};
		const handleClose = (tag, index) => {
			let length = tags.length - 1;
			// 处理vuex的tabList
			store.commit("closeTab", tag);
			// 第一个判断
			if (tag.name !== route.name) {
				return;
			}
			if (index == length) {
				router.push({ name: tags[index - 1].name });
			} else {
				router.push({ name: tags[index].name });
			}
		};
		return {
			tags,
			changMenu,
			handleClose,
			clickPrev,
			clickNext,
		};
	},
};
</script>
<style lang="less" scoped>
.wrap {
	width: 100%;
	overflow: hidden;
	padding: 0 20px;
	box-sizing: border-box;
	position: relative;
}
.tags {
	padding: 20px 0 8px;
	box-sizing: border-box;
	position: relative;
	z-index: 5;
	white-space: nowrap;
	overflow: auto;
	margin-left: 25px;
	margin-right: 25px;
	transition: all ease-in-out 5s;
	.el-tag {
		margin-right: 15px;
		cursor: pointer;
		border: none;
		color: #666;
	}
	.el-tag:nth-child(1) {
		position: sticky;
		z-index: 10;
		left: 0;
	}
}
::-webkit-scrollbar {
	height: 0;
}
// ::-webkit-scrollbar-thumb {
//   background-color: #7da7f0;
// }
// ::-webkit-scrollbar-track {
//   background-color: #fff;
// }
.isShow {
	display: inline-block;
	width: 10px;
	height: 10px;
	background-color: #3680fd;
	margin-right: 3px;
	border-radius: 50%;
}
.prev {
	position: absolute;
	left: 15px;
	top: 50%;
	transform: translateY(-30%);
	z-index: 50;
	width: 25px;
	height: 25px;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	// border: 1px solid #aaa;
}
.next {
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-30%);
	z-index: 50;
	width: 25px;
	height: 25px;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
