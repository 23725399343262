<template>
  <div class="header">
    <div class="l-content">
      <el-button size="small" class="bbuu" @click="handCollapse">
        <el-icon :size="20">
          <Menu />
        </el-icon>
      </el-button>
      <div>成都市xxx财务管理平台</div>
      <div class="qh">
        <img src="../assets/images/qiehuan.svg" alt="" />
      </div>
    </div>
    <div class="r-content">
      <el-dropdown>
        <span class="el-dropdown-link">
          <img class="user" :src="imgSrc" alt="" />
          <div class="username">xxx海运有限公司</div>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="remove()" class="tan">退&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;出</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  setup() {
    let store = useStore();
    let router=useRouter();
    const imgSrc = require("@/assets/images/user.png");
    const handCollapse = () => {
      store.commit("updateIsCollapse");
    };
    const remove=()=>{
      router.push({ path: '/login' });
      localStorage.removeItem('token')
    }
    // 计算属性
    const current = computed(() => {
      return store.state.currentMenu;
    });
    return {
      imgSrc,
      handCollapse,
      current,
      remove,
    };
  },
};
</script>
<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  color: #000;
  overflow: hidden;
}

.r-content {
  .user {
    width: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 8px;
  }
}

.l-content {
  display: flex;
  align-items: center;
}

h3 {
  color: #fff;
  margin-left: 10px;
}

.bbuu {
  margin: 10px;
}
.bread /deep/span {
  color: #fff !important;
  cursor: pointer !important;
}
.qh {
  width: 51px;
  height: 22px;
  margin-left: 10px;
}
.el-dropdown-link {
  margin-right: 20px;
  display: flex;
  align-items: center;
  color: #fff;
}
.username {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #000000;
  line-height: 22px;
  text-align: left;
  font-style: normal;
}
// .tan{
// width: 100px;
// }
</style>
