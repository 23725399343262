import { createRouter, createWebHashHistory } from "vue-router";
import main from "../views/main.vue";

const routes = [
	{
		path: "/",
		name: "main",
		component: main,
		children: [
			{
				path: "/",
				name: "home",
				component: () => import("../views/home/home.vue"),
			},
			{
				path: "/user",
				name: "user",
				component: () => import("../views/user/user.vue"),
			},
			{
				path: "/page1",
				name: "page1",
				component: () => import("../views/page/page1.vue"),
			},
			{
				path: "/page2",
				name: "page2",
				component: () => import("../views/page/page2.vue"),
			},
			{
				path: "/zhanghu",
				name: "zhanghu",
				component: () => import("../views/page/zhanghu.vue"),
			},
			{
				path: "/bumenxinxi",
				name: "bumenxinxi",
				component: () => import("../views/page/bumenxinxi.vue"),
			},
			{
				path: "/lixiang",
				name: "lixiang",
				component: () => import("../views/page/yusuanlixiang.vue"),
			},
			{
				path: "/renwu",
				name: "renwu",
				component: () => import("../views/page/yusuanrenwu.vue"),
			},
			{
				path: "/tiaomu",
				name: "tiaomu",
				component: () => import("../views/page/tiaomu.vue"),
			},
			{
				path: "/liucheng",
				name: "liucheng",
				component: () => import("../views/page/liucheng.vue"),
			},
			{
				path: "/shenqing",
				name: "shenqing",
				component: () => import("../views/page/baoxiao/bxshenqing.vue"),
			},
			{
				path: "/caogao",
				name: "caogao",
				component: () => import("../views/page/baoxiao/bxcaogao.vue"),
			},
			{
				path: "/daishenpi",
				name: "daishenpi",
				component: () =>
					import("../views/page/baoxiao/bxdaishenpi.vue"),
			},
			{
				path: "/shenpizhong",
				name: "shenpizhong",
				component: () =>
					import("../views/page/baoxiao/bxshenpizhong.vue"),
			},
			{
				path: "/yibaoxiao",
				name: "yibaoxiao",
				component: () =>
					import("../views/page/baoxiao/bxyibaoxiao.vue"),
			},
			{
				path: "/yishenpi",
				name: "yishenpi",
				component: () => import("../views/page/baoxiao/bxyishenpi.vue"),
			},
			{
				path: "/peizhi",
				name: "peizhi",
				component: () => import("../views/page/baoxiao/bxpeizhi.vue"),
			},
			{
				path: "/cjyjgz",
				name: "cjyjgz",
				component: () => import("../views/temp/cjyjgz.vue"),
			},
			{
				path: "/cjgzlb",
				name: "cjgzlb",
				component: () => import("../views/temp/yjgzlb.vue"),
			},
			{
				path: "/cf",
				name: "cf",
				component: () => import("../views/temp/ysbz_cf.vue"),
			},
			{
				path: "/dbz",
				name: "dbz",
				component: () => import("../views/temp/ysbz_dbz.vue"),
			},
			{
				path: "/dsh",
				name: "dsh",
				component: () => import("../views/temp/ysbz_dsh.vue"),
			},
			{
				path: "/sh",
				name: "sh",
				component: () => import("../views/temp/ysbz_sh.vue"),
			},
			{
				path: "/tj",
				name: "tj",
				component: () => import("../views/temp/ysbz_tj.vue"),
			},
			{
				path: "/wdrw",
				name: "wdrw",
				component: () => import("../views/temp/ysbz_wdrw.vue"),
			},
			{
				path: "/ysh",
				name: "ysh",
				component: () => import("../views/temp/ystj_sh.vue"),
			},
			{
				path: "/ysq",
				name: "ysq",
				component: () => import("../views/temp/ystj_sq.vue"),
			},
			{
				path: "/zsh",
				name: "zsh",
				component: () => import("../views/temp/ystz_sh.vue"),
			},
			{
				path: "/zsq",
				name: "zsq",
				component: () => import("../views/temp/ystz_sq.vue"),
			},
			{
				path: "/kmlbpz",
				name: "kmlbpz",
				component: () => import("../views/pzx/kmlbpz.vue"),
			},
			{
				path: "/kmxzpz",
				name: "kmxzpz",
				component: () => import("../views/pzx/kmxzpz.vue"),
			},
			{
				path: "/wllxpz",
				name: "wllxpz",
				component: () => import("../views/pzx/wllxpz.vue"),
			},
			{
				path: "/bdlxpz",
				name: "bdlxpz",
				component: () => import("../views/pzx/bdlxpz.vue"),
			},
			{
				path: "/ndpz",
				name: "ndpz",
				component: () => import("../views/pzx/ndpz.vue"),
			},
			{
				path: "/ybpz",
				name: "ybpz",
				component: () => import("../views/pzx/ybpz.vue"),
			},
			{
				path: "/yslxpz",
				name: "yslxpz",
				component: () => import("../views/pzx/yslxpz.vue"),
			},
			{
				path: "/kjkmpz",
				name: "kjkmpz",
				component: () => import("../views/pzx/kjkmpz.vue"),
			},
			{
				path: "/lcpz",
				name: "lcpz",
				component: () => import("../views/pzx/lcpz.vue"),
			},
			{
				path: "/qxpz",
				name: "qxpz",
				component: () => import("../views/pzx/qxpz.vue"),
			},
		],
	},
	{
		path: "/login",
		name: "login",
		component: () => import("../views/login/login.vue"),
	},
	// {
	//   path: '/about',
	//   name: 'about',
	//   // route level code-splitting
	//   // this generates a separate chunk (about.[hash].js) for this route
	//   // which is lazy-loaded when the route is visited.
	//   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
	// }
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

router.beforeEach(async (to, from) => {
	if (
		// 检查用户是否已登录
		!localStorage.getItem("token") &&
		// ❗️ 避免无限重定向
		to.name !== "login"
	) {
		// 将用户重定向到登录页面
		return { name: "login" };
	}
});
export default router;
