import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import App from "./App.vue";
import router from "./router";
import "./api/mock.js";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import store from "./store";
import zhCn from "element-plus/es/locale/lang/zh-cn";
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component);
}

app.use(router).use(store);
app.use(ElementPlus, { locale: zhCn });
app.mount("#app");
